.packageContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: calc(100vh - 184px);
}

.packageWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
}

.sliderWrapper {
    width: 35%;
}


@media screen and (max-width: 900px) {
    .sliderWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .packageWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

}