.container {
	display: flex;
	flex-direction: column;
	align-items: center;

	min-width: 320px;
	min-height: 420px;
	padding: 30px 20px;
	background-color: #ffffff;
	border-radius: 10px;
}

.title {
	font-size: 1.5em;
	margin-bottom: 40px;
	color: #21344A;
}

.form {
	display: flex;
	flex-direction: column;
}

.cancelBtn {
	position: absolute;
	top: 0;
	right: 0;
	color: #f50057;
	cursor: pointer;
}

.button:hover {
	opacity: .9;
}

.contactChoice {
	display: flex;
	margin-bottom: 20px;
}

.nativeSelect {
	padding-left: 10px;
	padding-bottom: -10px;
	margin-right: 10px;
	width: 35%;
	text-indent: 0;
}

.option {
	padding: 0 0 10px 10px;
}

.circularProgress {
	margin: 150px auto 0 auto;
}

@media screen and (max-width: 840px) {
	.container {
		width: 50%;
		padding: 50px 20px;
	}

	.title {
		text-align: center;
	}

	.nativeSelect {
		padding-left: 10px;
		margin-right: 10px;
		width: 35%;
		text-indent: 0;
	}
}

@media screen and (max-width: 530px) {
	.container {
		height: 100%;
		width: 100%;
		border-radius: 0;
	}
}

@media screen and (max-width: 530px) {
	.container {
		height: 100%;
		width: 100%;
	}
}