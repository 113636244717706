.ourWorkContainer {
    margin: 25px 0;
    width: 100%;
}

/*.prevArrow:before {*/
/*    position: absolute;*/
/*    left: 0;*/
/*    content: "<";*/
/*    color: red;*/
/*    font-size: 30px;*/
/*}*/

/*.nextArrow:before {*/
/*    position: absolute;*/
/*    left: 0;*/
/*    content: ">";*/
/*    color: red;*/
/*    font-size: 30px;*/
/*}*/

.ourWorkWrapper {
    width: 80%;
    margin: 0 auto;
}

.sliderContainer {
    border: 1px solid black;
    padding-top: 6px;
    border-radius: 5px;
}

.image {
    width: 100px;
    height: 300px;
    padding: 0 10px;
}

.slick-prev:before,
.slick-next:before {
    color: yellow;
}