code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  object-fit: cover;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  outline: none;
  -webkit-font-smoothing: antialiased;
}

* {
  position: relative;
}

body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 14px;
  overflow-x: hidden;
  /*min-width: 320px;*/
  /*min-height: 100vh;*/
  color: #21344A;
  line-height: 22px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 0;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(206, 206, 206, 0.85);
  border-radius: 0;
  border: 0;
  transition: all .2s ease-in-out;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  transition: all .2s ease-in-out;
}

a,
label {
  -webkit-tap-highlight-color: transparent;
}

.visually-hidden {
  width: 1px;
  height: 1px;
  margin: -1px;
  position: absolute;
  clip: rect(0 0 0 0);
}

.container {
  @extend %container;
  max-width: 1320px;
}

header,
main,
footer {
  flex-shrink: 0;
}

main {
}

footer {
  margin-top: auto;
}

.fancybox-navigation {
  position: static;
}
h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
}
p{
  margin: 0;
}
ul, li {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
a {
  color: inherit;
  text-decoration: none;
}
