.notebookContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: calc(100vh - 184px);
}

.notebookWrapper {
    display: flex;
    justify-items: center;
    width: 80%;
}

.sliderWrapper {
    width: 40%;
}


@media screen and (max-width: 900px) {
    .sliderWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .notebookWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

}