.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title {
    margin-top: 30px;
    font-size: 20px;
}
.icon {
    margin: 30px 0;
    width: 150px;
    height: 150px;
    color: #3c933c;
    border-radius: 50%;
}

.description {
    /*font-size: 20px;*/
}