.postalContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: calc(100vh - 184px);
    width: 100%;
}

.postalWrapper {
    display: flex;
    justify-content: flex-start;
    width: 80%;
}

.sliderWrapper {
    width: 40%;
}


@media screen and (max-width: 800px) {
    .sliderWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .postalWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}