.calendarContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    min-height: calc(100vh - 184px);
    width: 100%;
}

.calendarWrapper {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


@media screen and (max-width: 800px) {
    .calendarContainer {

    }

    .calendarWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}