.container {
    display: flex;
    border: 1px solid black;
    border-radius: 5px;
    height: 100%;
    position: relative;
}

.title {
    position: absolute;
    top: -23px;
    left: 2px;
}

.leftWrapper {
    display: flex;
    flex-direction: column;
    /*padding: 2px;*/
}

.rightWrapper {
    display: flex;
    justify-content: center;
}

.radioSelect {
    display: none;
}

.radioSelect:checked+.label{
    background-color: #f50057;
    color: white;
}

.label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 50px;
}

.text {
    white-space: nowrap;
    padding-left: 3px;
}

@media screen and (max-width: 800px ) {
    .label {
        font-size: 10px;
    }
}

@media screen and (max-width: 450px ) {
    .text {
        font-size: 10px;
    }

    .label {
        width: 44px;
        font-size: 10px;
    }
}

.count:hover {
    cursor: pointer;
    color: #21344A !important;
}

@media screen and (max-width: 360px) {
    .text {
        font-size: 10px;
    }
}

@media screen and (max-width: 330px) {
    .text {
        font-size: 10px;
    }
}