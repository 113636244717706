.container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	width: 600px;
	height: 200px;
	background-color: #f3f3f3;
	border-radius: 10px;
}

.buttonsWrapper {
	display: flex;
	justify-content: space-between;
	width: 80%;
}

.title {
	font-size: 1.8em;
	color: #21344A;
}

.cancelBtn {
	position: absolute;
	top: 0;
	right: 0;
	color: #f05;
	cursor: pointer;
}

.cancelBtn:hover {
	font-size: 2em;
	top: -2px;
	right: -2px;
}

@media screen and (max-width: 760px) {
	.container {
		width: 100%;
		height: 100%;
		border-radius: unset;
		max-height: 546px;
	}
	.title {
		line-height: 2em;
		text-align: center;
	}
	.buttonsWrapper {
		flex-direction: column;
		align-items: center;
		width: 80%;
		height: 150px;
	}
	.cancelBtn {
		margin: 5px 5px 0 0;
	}
}