.feedbackFormContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    margin: 20px auto;
}

.descriptionContainer {
    display: flex;
    flex-direction: column;
}

.headerDescription {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 20px;
    text-align: center;
}

.descriptionText {
    text-align: center;
}

.button:hover {
    opacity: .8;
}

@media screen and (max-width: 1600px) {
    .feedbackFormContainer {
        width: 60%;
    }
}

@media screen and (max-width: 1300px) {
    .feedbackFormContainer {
        width: 70%;
    }
}

@media screen and (max-width: 600px) {
    .feedbackFormContainer {
        flex-direction: column;
    }
    .descriptionContainer {
        margin-top: 30px;
    }
    .descriptionText {
        margin-bottom: 10px;
    }
}