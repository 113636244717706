.stepsContainer {
    width: 100%;
}

.stepsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
}

.title {
    font-weight: bold;
}

.steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.step {
    font-weight: 900;
}

.description {
    white-space: pre-line;
    margin-bottom: 7px;
}

.image {
    width: 800px;
    height: 300px;
}

@media screen and (max-width: 1600px){
    .stepsWrapper {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .image {
        display: none;
    }
}